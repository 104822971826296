@use './GlobalScss/mixins/mixins' as mixins;
@use './GlobalScss/variables' as variables;
@use './GlobalScss/mixins/type-mixins' as type-mixins;

#app-root {
  height: 100%;
  width: 100%;
  display: flex;
}

// Shell
.container {
  display: flex;
  height: 100%;
  width: 100%;
}

// Main content area
.content {
  flex-grow: 1;
  overflow: auto;
  padding: 60px;
  background-color: variables.$defaultBg; // Default theme
  color: variables.$defaultCopy; // Default theme

  @include mixins.breakpoint(largeDesktop) {
    padding: 50px;
  }

  @include mixins.breakpoint(mediumDesktop) {
    padding: 40px;
  }

  @include mixins.breakpoint(smallDesktop) {
    padding: 30px 40px;
  }

  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.noAppMessage {
  @include type-mixins.typeBody--md;

  a,
  a:hover,
  a:visited,
  a:active {
    color: variables.$defaultCopy;
    text-decoration: underline;
    font-weight: 600;
  }
}