@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/btn-mixins.scss' as bm;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/variables.scss' as v;

.panesWidget {
  &-frame {
    border: 2px solid v.$defaultMedium;
    border-radius: 8px;
  }

  &-loading {
    position: relative;
    height: 200px;
  }
}

  .paneButtons {
    margin-bottom: 20px;

    @include m.tablet {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
    }

    &--hidden {
      display: none;
    }
  }

    .paneButton {
      @include bm.btnReset;
      @include tm.typeCta--md;
      padding: 15px;
      border-radius: 8px;
      border: 2px solid var(--secondary);
      color: var(--secondary);
      background-color: white;
      box-shadow: 0 2px 3px rgba(0,0,0,0.2);
      display: inline-block;
      width: 100%;
      cursor: pointer;
      transition: v.$standardTrans;

      @include m.tablet {
        flex: 1;
        max-width: 300px;
      }

      & + & {
        margin-top: 10px;

        @include m.tablet {
          margin-top: 0;
          margin-left: 30px;
        }
      }

      &:hover {
        background-color: var(--secondary--lightened);
      }

      &--active {
        background-color: var(--secondary);
        color: white;
        box-shadow: none;

        &:hover {
          cursor: default;
          background-color: var(--secondary);
        }
      }
    }

    .subpane {
      padding: 30px 20px;

      @include m.tablet {
        padding: 40px;
      }
    }

  .subpaneButtons {
    text-align: left;

    &--scroller {
      @include m.tablet {
        display: flex;
      }
    }

    &-toggle {
      border-radius: 8px 0 0 0px;
      border-bottom: 2px solid v.$defaultMedium;
      background: v.$defaultBg;
      padding: 0 15px;
      display: flex;

      @include m.tablet {
        display: none;
      }

      &__currentSubpaneTitle {
        color: v.$defaultCopy;
        @include tm.typeDisplay--md;
        text-align: left;
        padding: 15px 0;
        flex:1;
      }

      &__button {
        flex: 0;
        transition: v.$standardTrans;

        &:hover {
          transform: scale(1.1);
          transition: v.$standardTrans;
        }

        &--active,
        &--active:hover {
          transition: v.$standardTrans;
          margin-top: -1px;
          transform: scale(1) rotate(180deg);
        }

        &--active:hover {
          transform: scale(1.1) rotate(180deg);
        }

        &__caret {
          fill: v.$defaultCopy;
          width: 31px;
          height: 16px;
        }
      }
    }

    &-previous,
    &-next {
      background: v.$defaultMedium;
      padding: 0 20px;
      flex: 0;
      display: none;
      margin:0;

      @include m.tablet {
        display: block;
      }

      &--active {

      }

      &__arrow {
        fill: v.$defaultWhite;
        height: 31px;
        width: 16px;
        transition: v.$standardTrans;
        opacity: 0.5;

        .subpaneButtons-previous--active &,
        .subpaneButtons-next--active & {
          opacity: 1;
        }
      }
    }

      &-previous__arrow {
        .subpaneButtons-previous--active:hover & {
          transform: translateX(-10px);
        }
      }

      &-next__arrow {
        transform: rotate(180deg);

        .subpaneButtons-next--active:hover & {
          transform: rotate(180deg) translateX(-10px);
        }
      }

    &-nav {

      @include m.tablet {
        overflow: hidden;
        border-radius: 8px 8px 0 0 ;
      }

      &--scroller {
        @include m.tablet {
          flex: 1;
          border-radius:0;
          width: 100px; //including a set width makes the scroller expand to the correct width, i don't know why
        }
      }

      &__scrollerPane {
        transition: v.$standardTrans;

        @include m.tablet {
          display: flex;
        }
      }

      &__button {
        @include bm.btnReset;
        display: block;
        width: 100%;
        padding: 15px;
        border-bottom: 2px solid v.$defaultMedium;
        color: v.$defaultCopy;
        @include tm.typeDisplay;
        text-align: left;
        font-size: 18px;
        margin: 0;

        @include m.tablet {
          background: v.$defaultBg;
          display: inline-block;
          width: auto;
          position: relative;
          white-space: nowrap
        }

        &:after {
          @include m.tablet {
            content: '';
            position: absolute;
            background: v.$defaultMedium;
            top: 0;
            right: 0;
            bottom: 0;
            width: 2px;
            display: block;
          }
        }

        .subpaneButtons--scroller &:after {
          bottom: -2px;
        }

        // &:first-child {
        //   @include m.tablet {
        //     border-top-left-radius: 8px;
        //   }
        // }

        // &:last-child {
        //   @include m.tablet {
        //   }
        // }

        &--active {
          background: v.$defaultCopy;
          color: v.$defaultWhite;

          @include m.tablet {
            background: v.$defaultWhite;
            color: v.$defaultCopy;
            border-bottom: none;
          }

          &:last-child:after {
            @include m.tablet {
              display: none;
            }
          }
        }
      }
    }
  }