@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/mixins/btn-mixins.scss' as bm;
@use '../../GlobalScss/variables.scss' as v;

.onImageDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: absolute;
  z-index: 7000;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 20px 50px v.$defaultShadow;
  border-radius: 45px;

  &__activeOption {
    @include tm.typeCta--lgFixed;
    padding: 12px 22px;
    border-radius: 45px;
    transition: all 0.3s ease;
    color: v.$defaultCopy;
  }

  &__toggleAndOptions {
    background-color: v.$defaultWhite;
    position: relative;
    padding: 8px 4px;
    border-radius: 45px;
    transition: all 0.3s ease;

    @include m.mediumDesktop {
      padding: 12px 8px;
    }

    &--active {
      background-color: v.$defaultCopy; // Default theme
    }
  }

    &__toggleArrowWrap {
      display: block;
      position: relative;
      width: 36px;
      height: 38px;
      margin: 0 5px;
      opacity: 0.8;
      transform-origin: 50% 50%;
      top: 2px;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }

      .onImageDropdown__toggleAndOptions--active & {
        transform: scale(1) rotate(180deg);
        top: -2px;

        &:hover {
          transform: scale(1.1) rotate(180deg);
        }
      }
    }

    &__toggleArrow {
      fill: v.$defaultCopy; // Default theme
      transition: all 0.3s ease;
      pointer-events: none;

      .onImageDropdown__toggleAndOptions--active & {
        fill: v.$defaultBg; // Default theme
      }
    }

    &__options {
      bottom: 70px;
      position: absolute;
      z-index: 7000;

      background-color: v.$defaultWhite;
      border-radius: 10px;
      box-shadow: 0 0 30px 0 v.$defaultShadow; // Default theme
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease;

      @include m.largeDesktop {
        bottom: 80px;
      }

      &--right {
        left: 50%;
        transform: translateX(-50%);
      }

      &--altRight {
        right: 0;
      }

      &--left {

      }

      .onImageDropdown__toggleAndOptions--active & {
        opacity: 1;
        pointer-events: all;
        transition: all 0.3s ease;
      }
    }

      &__option {
        @include bm.btnReset;
        @include tm.typeCtaAlt--mdFixed;
        white-space: nowrap;
        display: block;
        width: 100%;
        padding: 10px 22px 8px;
        border-bottom: 1px solid lighten(v.$defaultCopy, 50%); // Default theme
        color: v.$defaultCopy; // Default theme
        text-align: center;
        margin: 0; // override safari defaults

        @include m.largeDesktop {
          padding: 12px 26px 10px;
        }

        &:last-child {
          border: none;
        }

        &:hover {
          background-color: v.$defaultBg; // Default theme
        }

        &--active,
        &--active:hover {
          background-color: v.$defaultCopy; // Default theme
          color: v.$defaultBg;
        }
      }
}

