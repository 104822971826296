@use '../../GlobalScss/variables.scss' as v;

.homescreen {
  flex-grow: 1;
  overflow: auto;
  background-color: v.$defaultBg; // Default theme;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.homescreenSlideshow {
  position: absolute;
  // width: 100vw;
  // height: 100vh;
  width:100%;
  height: 100%;
  //overflow: hidden;
}

.homescreenSlideshowImage {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  // animation-timing-function: linear;
  // animation-iteration-count: infinite;
}

//now in the js

// v.$items: 13; // Change number based on how many pictures are in the rotation
// v.$animation-time: 4s;
// v.$transition-time: .5s;
// v.$scale: 10%;

// v.$total-time: (v.$animation-time * v.$items);
// v.$scale-base-1: (1 + v.$scale / 100%);

// @for v.$i from 1 through v.$items {
//   @keyframes kenburns-#{v.$i} {
//     v.$animation-time-percent: percentage(v.$animation-time / v.$total-time);
//     v.$transition-time-percent: percentage(v.$transition-time / v.$total-time);

//     v.$t1: (v.$animation-time-percent * (v.$i - 1) - v.$transition-time-percent / 2);
//     v.$t2: (v.$animation-time-percent * (v.$i - 1) + v.$transition-time-percent / 2);
//     @if(v.$t1 < 0%) { v.$t1: 0%; }
//     @if(v.$t2 < 0%) { v.$t2: 0%; }

//     v.$t3: (v.$animation-time-percent * (v.$i) - v.$transition-time-percent / 2);
//     v.$t4: (v.$animation-time-percent * (v.$i) + v.$transition-time-percent / 2);
//     @if(v.$t3 > 100%) { v.$t3: 100%; }
//     @if(v.$t4 > 100%) { v.$t4: 100%; }

//     v.$t5: (100% - v.$transition-time-percent / 2);
//     v.$t6: ((v.$t4 - v.$t1) * 100% / v.$t5);

//     #{v.$t1} { opacity: 1; transform: scale(v.$scale-base-1); }
//     #{v.$t2} { opacity: 1; }
//     #{v.$t3} { opacity: 1; }
//     #{v.$t4} { opacity: 0; transform: scale(1); }

//     @if(v.$i != v.$items) {
//       100% { opacity: 0; transform: scale(v.$scale-base-1); }
//     }

//     @if(v.$i == 1) {
//       v.$scale-plus: (v.$scale * (100% - v.$t5) / v.$t4);
//       v.$scale-plus-base-1: (1 + (v.$scale + v.$scale-plus) / 100%);

//       #{v.$t5} { opacity: 0; transform: scale(v.$scale-plus-base-1); }
//       100% { opacity: 1; }
//     }
//   }
// }
