@use '../../GlobalScss/mixins/mixins.scss' as m;

.finishCollection {
  position: relative;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: blur(5px) opacity(50%);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transition: background-image 400ms;
  }

  &__palettes,
  &__spaces {
    position: absolute;
    bottom: 20px;

    @include m.smallDesktop {
      bottom: 30px;
    }
  }

  &__spaces {
    left: 20px;

    @include m.smallDesktop {
      left: 30px;
    }
  }

  &__palettes {
    right: 20px;

    @include m.smallDesktop {
      right: 30px;
    }
  }

  &__blueFingerprint {
    margin-top: 20px;
    text-align: center;

    @include m.smallDesktop {
      margin-top: 30px;
    }

    &Link {
      display: inline-block;
    }
  }
}
