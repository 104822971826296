@use '../../GlobalScss/variables.scss' as v;

.loadingSpinnerContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: v.$defaultBg;

  svg {
    stroke: v.$defaultCopy;
  }
}
