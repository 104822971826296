@use '../../GlobalScss/variables.scss' as v;
@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/btn-mixins.scss' as bm;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;

.myLifeSiteLink {

  &__banner {
    position: relative;
    padding: 22px 100px 22px 30px;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: v.$defaultCopy;
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    transition: v.$standardTrans;

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__icon {
    @include bm.btnReset;
    position: fixed;
    display: block;
    width: 60px;
    height: 60px;
    background: url(MyLifeSiteLink-icon.svg) no-repeat;
    bottom: -28px;
    right: 20px;
    z-index: 300;
    transition: v.$standardTrans;

    &:hover {
      bottom: -20px;
    }

    &--active {
      bottom: 18px;
      cursor: default;

      &:hover {
        bottom: 18px;
      }
    }
  }

  &__link {
    display: block;
    text-align: right;
    color: v.$defaultWhite;
    transition: v.$standardTrans;

    &:hover {
      transform: scale(1.05);
    }
  }

    &__cta {
      @include tm.typeCta;
      font-size: 20px;
    }

    &__attribution {
      @include tm.typeCtaAlt;
      font-size: 17px;
      opacity: 0.5;
    }

  &__close {
    @include bm.btnReset;
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background-color: v.$defaultCopy;
    position: absolute;
    top: -17px;
    left: -17px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      display: block;
      background-color: v.$defaultWhite;
      transition: v.$standardTrans;
    }

    &:before {
      width: 18px;
      height: 2px;
    }

    &:after {
      width: 2px;
      height: 18px;
    }
  }
}