// Clear fix
@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

// Visually hidden
@mixin sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Box sizing
@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

// breakpoints
@mixin phone {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin smallDesktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin smallMediumDesktop  {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin mediumDesktop  {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin largeDesktop  {
  @media (min-width: 1600px) {
    @content;
  }
}

// these are not mobile first - dont use them going forward
// @TODO - replace them with the above breakpoint mixins
@mixin breakpoint($point) {
  // General
  @if $point == largeDesktop {
    @media (max-width: 1600px) {
      @content;
    }
  } @else if $point == mediumDesktop {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $point == smallMediumDesktop {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if $point == smallDesktop {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: 768px) {
      @content;
    }
  }
}
