@use "../../../GlobalScss/variables" as v;
@use "../../../GlobalScss/mixins/_type-mixins.scss" as t;

.siteSeeGuide {
  text-align: center;
  position: relative;

  &__tipTrigger {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 25px;
    font-size: 20px;
    color: v.$defaultCopy;
    @include t.typeDisplay;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 20px 50px v.$defaultShadow;
    position: absolute;
    right: -12.5px;
    top: -25px;
    z-index: 6000;
  }

  &__modalArrow {
    position: absolute;
    width: 0;
    height: 0;
    border: 15px solid transparent;
  }

  &__text {
    padding-bottom: 20px;

    ul {
      text-align: left;
      list-style: none;
      padding: 0;
      margin: 0;

      li + li {
        margin-top: 10px;
      }
    }
  }

  &__icon {
    display: inline-block;
    height: 28px;
    width: 28px;
    vertical-align: middle;

    &--inline {
      margin: 0 2px;

    }

    &--left {
      margin: 0 10px 0 0;
    }

    svg {
      width: 100%;
      height: 100%;

      path,
      .pathlike {
        fill: v.$defaultCopy;
      }
    }
  }

  &__titleWrapper {
    display: flex;
    width: 100%;
  }

  &__title {
    background: v.$defaultBg;
    color: v.$defaultCopy;
    border-radius: 8px;
    @include t.typeCta--md;
    padding: 10px 20px;
    flex: 1;

    &--tour {
      text-align: left;
    }
  }

  &__btn {
    background: v.$defaultCopy;
    color: v.$defaultWhite;
    @include t.typeCta--md;
    padding: 10px 20px;
    border-radius: 0 8px 8px 0;
  }

  &__closeBtn {
    margin-top: 30px;
  }
}