@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/mixins/btn-mixins.scss' as bm;
@use '../../GlobalScss/variables.scss' as v;

.modalLite-enter {
  opacity: 0;
}

.modalLite-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.modalLite-exit {
  opacity: 1;
}

.modalLite-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.modalContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: map-get(v.$stacking-order, 'modal');
}

.blur {
  position: absolute;
  z-index: 9000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: rgba(233, 237, 221, 0.65);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.modal {
  position: absolute;
  z-index: 9998;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  max-height: 100%;
  width: 90%;
  max-width: 1200px;
  background-color: v.$defaultWhite;
  padding: 0 40px 30px;
  box-shadow: 0 40px 70px 0 v.$defaultShadow; // Default theme color
  overflow-y: scroll;

  @include m.smallMediumDesktop {
    padding: 0 50px 40px;
  }

  &--noHeader {
    padding-top: 30px;

    @include m.smallMediumDesktop {
      padding-top: 40px;
    }
  }

  &--floating {
    background: #FFFFFF;
    box-shadow: 0 24px 42px 0 #AFB0AA;
    border-radius: 12px;
    padding: 30px 40px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    overflow: visible;
  }

  &--positioned {
    box-shadow: 0 24px 42px 0 #AFB0AA;
    border-radius: 12px;
    padding: 30px 40px;
    text-align: center;
    overflow-y: visible;
  }

  &--small {
    width: 60%;

    @include m.smallDesktop {
      width: 38%;
    }
  }

  &--small-medium {
    width: 70%;

    @include m.smallDesktop {
      width: 90%;
    }
  }

  &--medium {
    width: 90%;

    @include m.smallDesktop {
      width: 80%;
    }
  }

  &--tip {
    bottom: 40px;
  }

  &--dialog {
    width: 600px;
    text-align: center;
  }
}

.modalHeader {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 90%;
  max-width: 1200px;
  background-color: v.$defaultWhite;
  left: 50%;
  transform: translateX(-50%);
  padding: 40px 50px;

  @include m.breakpoint(smallMediumDesktop) {
    padding: 30px 40px;
  }
}

// Close button
.modalClose {
  display: block;
  position: absolute;
  top: 36px;
  right: 50px;
  width: 60px;
  height: 60px;
  background-color: v.$defaultWhite;
  border-radius: 30px;
  border: 3px solid v.$defaultCopy; // Default theme
  transition: all 0.3s ease;

  .modal-scroll-active & {
    top: 25px;

    @include m.breakpoint(smallMediumDesktop) {
      top: 18px;
    }

    @include m.breakpoint(smallDesktop) {
      top: 15px;
    }
  }

  @include m.breakpoint(smallMediumDesktop) {
    margin-left: 30px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    top: 25px;
    right: 40px;
  }

  &:hover {
    background-color: v.$defaultCopy; // Default theme

    span {
      &:before,
      &:after {
        background-color: v.$defaultWhite;
      }
    }
  }

  span {
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    font-size:0;

    @include m.breakpoint(smallMediumDesktop) {
      width: 28px;
      height: 28px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 3px;
      margin-top: -1px;
      width: 100%;
      top: 50%;
      left: 0;
      border-radius: 5px;
      background: v.$defaultCopy; // Default theme
      transition: all 0.3s ease;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.modal__close--alt {
  display: block;
  position: absolute;
  top: -20px;
  right: -20px;
  width: 41px;
  height: 41px;
  background-color: v.$defaultCopy;
  border-radius: 50%;
  transition: all 0.3s ease;

  span {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    font-size:0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 3px;
      margin-top: -1px;
      width: 100%;
      top: 50%;
      left: 0;
      border-radius: 5px;
      background: v.$defaultWhite;
      transition: all 0.3s ease;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.modalTitleArea {
  max-height: 100px; // Value doesn't matter but needed to animate auto height
  overflow: hidden;
  transition: all 0.3s ease;
  height: auto;

  .modal-scroll-active & {
    max-height: 0;
    opacity: 0;
  }
}

.modalTitle {
  // height: auto;
  transition: all 0.3s ease;
  color: v.$defaultCopy;
  @include tm.typeDisplay--xxl;
  width: 90%;

  &--note {
    margin-left: 20px;
  }

  &__featured {
    font-weight: 500;
  }

  &__tag {
    display: inline-block;
    margin-left: 20px;
    padding: 4px 10px;
    border-radius: 5px;
    background-color: v.$defaultCopy;
    color: v.$defaultWhite;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
    vertical-align: 7px;

    @include m.smallDesktop {
      font-size: 18px;
    }

    @include m.largeDesktop {
      font-size: 20px;
    }

    &--soldOut {
      background-color: v.$defaultAlert;
    }
  }
}

.modalSubtitle {
  color: v.$defaultCopy;
  @include tm.typeDisplay--lg;

  .modalTitle + & {
    margin-top: 10px;
  }

  &__group {
    display: inline-block;
    position: relative;

    & + & {
      padding-left: 20px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        display: block;
        width: 3px;
        height: 3px;
        margin-top: -1.5px;
        margin-left: -1.5px;
        border-radius: 50%;
        background-color: v.$defaultCopy;
      }
    }

    &__displayFees {
      .toggleDisplayFees & {
        display: none;
      }
    }
  }

  &__displayFees {
    color: v.$defaultCopy;
    text-decoration: underline;
  }
}

// Media type buttons
.modalNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  position: relative;
  transition: all 0.3s ease;

  .modal-scroll-active & {
    margin-top: 0;
    width: 90%;
  }
}

.modalNavItem {
  display: inline-block;
  color: var(--secondary);
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  @include tm.typeCta--md;

  & + & {
    margin-left: 20px;

    @include m.smallDesktop {
      margin-left: 40px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: -10px;
      height: 100%;
      width: 1px;
      background: lighten(v.$defaultCopy, 50%);

      @include m.smallDesktop {
        left: -20px;
      }
    }
  }

  &:hover {
    text-decoration: underline;
  }
}

.modalSection {
  border-radius: 5px;
  overflow: hidden;

  & + & {
    margin-top: 40px;
  }

  &--sixteen9 {
    padding-bottom: 52.65%;
  }

  &--border {
    border: 2px solid v.$defaultBg;
  }

  &--gallery {
    border-radius: 0;
    overflow: visible;

    .slideshowContainer {
      border-radius: 5px;
      overflow: hidden;    }
  }
}

.modalTable.modalSection__displayFees {
  max-height: 0;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  transition: max-height 500ms ease, margin 500ms ease, padding 500ms ease;

 .toggleDisplayFees & {
  max-height: 300px;
  padding: 20px 30px;
  margin-top: 40px;

    .modalList {
      /* @keyframes duration | easing-function | delay |
iteration-count | direction | fill-mode | play-state | name */
      animation:.5s ease .3s 1 both running fadeIn;
    }
  }

  .toggleDisplayFees.toggleDisplayFees__finished & {
    .modalList {
      opacity: 1;
      animation: none;
    }
  }
}

// info section
.modalTable {
  background-color: v.$defaultBg;
  border-radius: 5px;
  padding: 20px 30px;
  margin-top: 40px;
}

.modalList {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 1fr;
  text-align: center;
  color: v.$defaultCopy;

  @include m.smallDesktop {
    text-align: left;
    grid-template-columns: 1fr 1fr;
  }
 }

    .modalList__item {
      position: relative;
      @include tm.typeBody--md;

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        background-color: lighten(v.$defaultCopy, 50%); // Default theme
        bottom: -10px;
        left: 0;
        right: 0;
      }

      &:last-child:after {
        display: none;
      }

      &:nth-last-child(2):nth-child(odd):after {
        @include m.smallDesktop {
          display: none;
        }
      }
    }

  .modalList__label {
    @include tm.typeBody--md--bd;
  }

  .modalList__note {
    @include tm.typeBody--md--it;
  }

.modalDescription {
  @include tm.typeBody--md;
  color: v.$defaultCopy;

  .modalList + & {
    padding-top: 15px;
    margin-top: 10px;
    border-top: 1px solid lighten(v.$defaultCopy, 50%); // Default theme
  }

  .modalSubtitle + & {
    margin-top: 30px;

    @include m.smallMediumDesktop() {
      margin-top: 40px
    }
  }
}

.modalBtn {
  @include bm.btn;
  color: v.$defaultWhite;
  background-color: v.$defaultCopy;
  border: 2px solid v.$defaultCopy;
  @include tm.typeCta;
  font-weight: 400;
  font-size: 20px;
  padding: 10px 40px;

    &:hover {
      background-color: var(--primary--darkened);
    }

    &--secondary {
      background-color: var(--secondary);

      &:hover {
        background-color: var(--secondary--darkened);
      }
    }

  &:hover {
    color: v.$defaultWhite;
    background-color: v.$defaultCopy;
  }

  .modalDescription + & {
    margin-top: 30px;

    @include m.smallMediumDesktop() {
      margin-top: 40px
    }
  }
}

.modal__minorBtn {
  @include bm.minorBtn;
  color: v.$defaultCopy;
}

.modal__inlineBtns {
  display: flex;
  justify-content: center;

  .modalBtn + .modal__minorBtn {
    margin-left: 30px;
  }

  .modalDescription + & {
    margin-top: 30px;

    @include m.smallMediumDesktop() {
      margin-top: 40px
    }
  }
}