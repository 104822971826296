@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/variables.scss' as v;

.mapToolsInstructions {
  padding: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background: v.$defaultWhite;

  &--open {
    right: 0;
  }

  * {
    color: v.$defaultCopy;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.3;
  }

  h1,
  h2,
  ol {
    & + h1,
    & + h2,
    & + ol {
      margin-top: 20px;
    }
  }

  h1 {
    font-size: 150%;
  }

  h1,
  h2 {
    font-weight: bold;
    @include tm.typeDisplay--lg;
  }

  h3,
  ol {
    @include tm.typeBody--md;
    @include tm.typeBody--md--bd;
  }

  p,
  li {
    font-weight: normal;
    line-height: 1.5;
    @include tm.typeBody--md;
  }

  h3,
  h4,
  h5,
  p,
  li {
    & + h3,
    & + h4,
    & + h5,
    & + p,
    & + li {
      margin-top: 10px;
    }
  }

  button {
    background: v.$defaultCopy;
    color: v.$defaultWhite;
    padding: 5px;
    @include tm.typeCta--md;

    & + h1 {
      margin-top: 20px;
    }
  }
}
