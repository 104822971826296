@use '../../GlobalScss/variables.scss' as v;

.timeout {
  &__countdown {
    display: flex;
    justify-content: center;
  }

  &__countdownClock {
    font: 900 36px/1 v.$sans-serif;
    color: v.$defaultCopy;
    border-radius: 12px;
    background-color: v.$defaultBg;
    padding: 15px 30px;
  }

  &__resume {
    display: flex;
    justify-content: center;
  }
}
