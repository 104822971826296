@use '../../../GlobalScss/mixins/type-mixins' as tm;
@use '../../../GlobalScss/variables.scss' as v;

.zMarker {
  cursor: pointer;

  &__icon {
    &__doughnut,
    &__plus {
     fill: v.$defaultCopy;
    }

    &__bg {
      fill: v.$defaultWhite;
    }
  }

  &:hover {
    .zMarker__icon__doughnut,
    .zMarker__icon__plus {
      fill: v.$defaultWhite;
      transition: fill 0.3s ease;
    }

    .zMarker__icon__bg {
      fill: v.$defaultCopy;
      transition: fill 0.3s ease;
    }
  }

  &__bg {
    fill: v.$defaultWhite;

    &:hover {
      fill: v.$defaultCopy;
      transition: fill 0.3s ease;
    }
    .zMarker:hover & {
      fill: v.$defaultCopy;
      transition: fill 0.3s ease;
    }
  }

  &__text {
    @include tm.typeCtaAlt--mdFixed;
    fill: v.$defaultCopy;
    pointer-events: none;

    &:hover {
      fill: v.$defaultWhite;
      transition: fill 0.3s ease;
    }
    .zMarker:hover & {
      fill: v.$defaultWhite;
      transition: fill 0.3s ease;
    }
  }

}