@use '../../GlobalScss/mixins/mixins' as m;
@use '../../GlobalScss/mixins/type-mixins' as tm;
@use '../../GlobalScss/mixins/btn-mixins' as bm;
@use '../../GlobalScss/variables' as v;

.floatingModal {
  position: absolute;
  z-index: 9998;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  max-height: 100%;
  width: 90%;
  max-width: 1200px;
  background-color: v.$defaultWhite;
  padding: 0 40px 30px;
  box-shadow: 0 40px 70px 0 v.$defaultShadow; // Default theme color
  overflow-y: scroll;
  background: #FFFFFF;
  box-shadow: 0 24px 42px 0 #AFB0AA;
  border-radius: 12px;
  padding: 30px 40px;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;

  @include m.smallMediumDesktop {
    padding: 50px 40px;
  }

  &--small {
    width: 60%;

    @include m.smallDesktop {
      width: 38%;
    }
  }

  &--small-medium {
    width: 70%;

    @include m.smallDesktop {
      width: 90%;
    }
  }

  &--medium {
    width: 90%;

    @include m.smallDesktop {
      width: 80%;
    }
  }

  &__header {
    @include tm.typeDisplay;
    font-weight: 400;
    font-size: 36px;
    color: v.$defaultCopy;

    span {
      font-weight: 600;
    }
  }

  &__content {

  }

    &__copy {
      @include tm.typeText;
      font-size: 18px;
      color: v.$defaultCopy;
      margin-top: 15px;
      margin-bottom: 30px;
    }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

    &__cta {
      @include tm.typeCta;
      @include bm.btn;
      font-size: 400;
      font-size: 20px;
      background-color: var(--primary);
      color: #fff;
      padding: 10px 40px;

      &:hover {
        background-color: var(--primary--darkened);
      }

      &--secondary {
        background-color: var(--secondary);

        &:hover {
          background-color: var(--secondary--darkened);
        }
      }

      &--inactive {
        color: var(--primary);
        background: none;
        border: 3px solid var(--primary);

        &:hover {
          background: none;
        }
      }

      .floatingModal__header + & {
        margin-top: 30px;
      }
    }

    &__minorCta {
      @include tm.typeCta;
      @include bm.btn;
      text-transform: none;
      font-size: 400;
      font-size: 18px;
      text-decoration: underline;
      color: var(--secondary);
      margin-left: 50px;
    }

    &__inlineButton {
      @include tm.typeLabel;
      font-size: 16px;
      text-decoration: underline;
      display: block;
      color: v.$defaultCopy;
      cursor: pointer;
    }

    &__inlineButtonSeparator {
      @include tm.typeText;
      color: v.$defaultCopy;
      font-size: 16px;
      margin-left: 5px;
      margin-right: 5px;
    }






  // &__cta {
  //   @include m.btn;
  //   color: v.$defaultWhite;
  //   background-color: v.$defaultCopy;
  //   border: 2px solid v.$defaultCopy;

  //   &:hover {
  //     color: v.$defaultWhite;
  //     background-color: v.$defaultCopy;
  //   }

  //   .modalDescription + & {
  //     margin-top: 30px;

  //     @include m.smallMediumDesktop() {
  //       margin-top: 40px
  //     }
  //   }
  // }

}
