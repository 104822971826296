@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../../GlobalScss/variables.scss' as v;

.floorplans .gridItem {
  button {
    .imgContainer {
      &:before {
        display: none;
      }

      .imgHolder {
        width: 100%;
        height: 100%;
        background-color: v.$defaultWhite;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-origin: content-box;
        padding: 30px;
        transition: all 0.5s ease;
      }
    }
  }

  .floorplanInfo {
    text-align: center;
    padding: 15px 30px 0;

    h2 {
      font-size: 30px;
      color: v.$defaultCopy;
      font-weight: 400;
      margin-bottom: 5px;

      @include m.breakpoint(smallMediumDesktop) {
        font-size: 26px;
        margin-bottom: 3px;
      }
    }
  }
}

.floorplanInfo__subtitle {
  @include tm.typeBody--md;

  &__group {
    display: inline-block;
    position: relative;

    & + & {
      padding-left: 15px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 7.5px;
        display: block;
        width: 3px;
        height: 3px;
        margin-top: -1.5px;
        margin-left: -1.5px;
        border-radius: 50%;
        background-color: v.$defaultCopy;
      }
    }
  }
}