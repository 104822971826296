@use 'variables.scss' as v;

.toggleArrow {
  display: block;
  position: relative;
  width: 36px;
  height: 38px;
  margin: 0 5px;
  transition: all 0.3s ease;
  opacity: 0.8;
  transform-origin: 50% 50%;

  &:hover {
    opacity: 1;
  }

  &--up {
    top: -2px;
    transition: all 0.3s ease;
    transform: rotate(180deg);

    &:hover {
      transform: scale(1.1) rotate(180deg);
    }

    &--active {
      transform: scale(1) rotate(0);
      top: 2px;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.1) rotate(0);
      }
    }
  }

  &--down {
    top: 2px;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    &--active {
      transform: scale(1) rotate(180deg);
      top: -2px;

      &:hover {
        transform: scale(1.1) rotate(180deg);
      }
    }
  }
}

.toggleArrow-svg {
  fill: v.$defaultCopy; // Default theme
  transition: all 0.3s ease;
  pointer-events: none;

  &--active {
    fill: v.$defaultBg; // Default theme
    transition: all 0.3s ease;
  }
}
