@use 'type-mixins' as tm;
@use '../variables' as v;

@mixin btnReset {
  border: none;
  appearance: none;
  padding:0;
  outline: none;
  background: none;
  cursor: pointer;
}

@mixin btn {border-radius: 5px;
  padding: 10px;
  transition: v.$standardTrans;
  cursor: pointer;
}

@mixin secondaryBtn {
  @include btn;
  @include tm.typeCta--md;
  display: inline-block;
  background-color: v.$defaultWhite;
  border: 2px solid var(--secondary);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  color: var(--secondary);
}

@mixin secondaryBtn--active {
  background: var(--secondary);
  color: v.$defaultWhite;
  box-shadow: none;
}

@mixin minorBtn {
  @include tm.typeCta;
  transition: v.$standardTrans;
  cursor: pointer;
  text-transform: none;
  font-size: 20px;
  text-decoration: underline;
}

@mixin svgDynamicStroke {
  stroke: var(--secondary);
}

@mixin upArrowGraphic($width, $color) {
  width: $width;
  display: inline-block;
  position: relative;
  height: $width/2;

  &:before,
  &:after {
    content:"";
    bottom: 0;
    position: absolute;
    width: $width/1.3;
    height: 3px;
    background-color: $color;
    border-radius: 3px;
  }

  &:before {
    transform-origin: bottom left;
    left: 0;
    transform: rotate(-45deg);
  }

  &:after {
    transform-origin: bottom right;
    right: 0;
    transform: rotate(45deg);
  }

}

@mixin pulse {
  transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}