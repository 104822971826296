@use '../../GlobalScss/variables' as v;

.video {
  margin-top: 40px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.video__placeholder {
  padding: 56.25% 0 0 0;
  position: relative;
}

.video__player {
  position: absolute;
  left: 0;
  top: 0;
}