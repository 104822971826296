@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/mixins/btn-mixins.scss' as bm;
@use '../../GlobalScss/variables.scss' as v;

$table-border-width: 1px;

.personalization__form-header {
  @include tm.typeDisplay;
  font-weight: 400;
  font-size: 36px;
  color: v.$defaultCopy;
  text-align: center;

  span {
    font-weight: 600;
  }
}

.personalization__instructions {
  @include tm.typeText;
  font-size: 18px;
  color: v.$defaultCopy;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
}

/**
 * Form styles that apply to all personalization form steps.
 */
.personalization-form {

  label {
    @include tm.typeLabel;
    font-size: 18px;
    color: v.$defaultCopy;
    margin-bottom: 5px;
  }

  input {
    @include tm.typeData;
    color: v.$defaultCopy;
    font-size: 18px;
    border: 3px solid v.$defaultCopy;
    border-radius: 5px;
    padding: 10px 15px;

    &:focus,
    &:focus-visible {
      background: #FFFBE7;
      border-color: v.$defaultCopy;
      outline: none;
    }
  }

  .form-msg {
    @include tm.typeData;
    color: v.$defaultCopy;
    font-size: 16px;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;

    svg {
      flex: 0 0 20px;
    }

    .form-msg__text {
      flex: auto;
      margin-left: 10px;
    }
  }

  .form-error-msg {
    @include tm.typeData;
    font-size: 16px;
    color: v.$defaultFormErr;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;

    svg {
      flex: 0 0 20px;
    }

    .form-error-msg__text {
      flex: auto;
      margin-left: 10px;
    }
  }

  .form__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .form__item {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    margin-right: 40px;

    &.form-error {
      label {
        color: v.$defaultFormErr;
      }

      input {
        background: v.$defaultFormErrBg;
        color: v.$defaultFormErr;
        border-color: v.$defaultFormErr;
      }
    }
  }

  .hasWholeFormErrors {
    label {
      color: v.$defaultFormErr;
    }

    input {
      background: v.$defaultFormErrBg;
      color: v.$defaultFormErr;
      border-color: v.$defaultFormErr;
    }
  }
}

.personalization__error-box {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.personalization__error-box::before {
  content: "⚠️";
  font-size: 1.2em;
  margin-right: 0.5rem;
}

.personalization__error-message {
  @include tm.typeLabel;
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.personalization__cta {
  @include tm.typeCta;
  @include bm.btn;
  font-size: 400;
  font-size: 20px;
  background-color: #E18256;
  color: #fff;
  padding: 10px 30px;
  text-align: center;

  &:hover {
    background-color: var(--primary--darkened);
  }

  &[disabled] {
    opacity: 0.4;
    cursor: default;

    &:hover {
      transform: none;
    }
  }

  &--secondary {
    background-color: var(--secondary);

    &:hover {
      background-color: var(--secondary--darkened);
    }
  }

  &--inactive {
    color: var(--primary);
    background: none;
    border: 3px solid var(--primary);

    &:hover {
      background: none;
    }
  }

  &--working {
    &[disabled] {
      @include bm.pulse;
    }
  }
}

.personalization__cta-minor {
  @include bm.minorBtn;
  color: var(--secondary);
}

.personalization__cta-minor-alt {
  @include tm.typeCta;
  @include bm.btn;
  text-transform: none;
  font-size: 400;
  font-size: 20px;
  text-decoration: underline;
  color: #E18256;
}

.personalization__saved-message {
  @include tm.typeText;
  background-color: v.$defaultBg;
  color: v.$defaultCopy;
  font-size: 16px;
  font-style: italic;
  text-align: center;
  padding: 15px;
  margin-bottom: 26px;
  border-radius: 5px;
}

.personalization__cta-welcome {
  @include tm.typeCta;
  @include bm.btn;
  text-transform: none;
  font-size: 400;
  font-size: 18px;
  text-decoration: underline;
  color: #E18256;
}

.personalization__inline-buttons-end {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.personalization__inline-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.personalization__inline-buttons-close {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .personalization__cta-minor {
    @include tm.typeLabel;
    color: v.$defaultCopy;
    margin-left: 0;
    font-size: 16px;
  }
}

.personalization__welcome-message-variations {
  @include tm.typeText;
  font-size: 18px;
  color: v.$defaultCopy;
  margin-top: 40px;

  .rephrasing-loading {
    padding: 50px 20px;
    background-color: v.$defaultBg;
    margin-bottom: 20px;
  }

  div.no-messages {
    padding: 50px 20px;
    background-color: v.$defaultBg;
    text-align: center;
  }

  p.no-messages {
    padding: 96px 20px;
    background-color: v.$defaultBg;
    text-align: center;
  }
}

.personalization__variation {
  background-color: v.$defaultBg;
  padding: 30px 40px;
  margin-bottom: 20px;
  border-radius: 8px;

  .personalization__cta-minor-alt {
    color: v.$defaultCopy;
  }
}

.personalization__variation-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.personalization__variation-title {
  @include tm.typeDisplay;
  font-weight: bold;
  font-size: 18px;
  color: v.$defaultCopy;
  margin: 0;
}

.personalization__variation-links {
  display: flex;
}

.personalization__variation-link {
  @include tm.typeLabel;
  font-size: 16px;
  font-weight: normal;
  color: v.$defaultCopy;
  text-decoration: underline;
  margin-left: 20px;

  &:hover {
    text-decoration: none;
  }
}

.personalization__variation-text {
  margin-bottom: 20px;
}

.personalization__variation-actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.personalization__inline-button {
  @include tm.typeLabel;
  font-size: 16px;
  text-decoration: underline;
  display: block;
  color: v.$defaultCopy;
  cursor: pointer;
}

.personalization__inline-button-separator {
  @include tm.typeText;
  color: v.$defaultCopy;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
}


/**
 * Form Step 1.
 *
 * Form step where user enters data for CRM lookup.
 */
.personalization__form-lookup-step {

  .form__item {
    width: calc((100%/2) - (40px/2));

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(3n) {
      width: 100%;
      margin-right: 0;
    }
  }

  .personalization__cta {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/**
 * Form Step 2.
 *
 * Form step where user is selecting from multiple returned values.
 */
.personalization__form-selection-step {

  // The form fields are being hidden because they are being set in the
  // background and the user doesn't interact with this input directly.
  .form__items {
    display: none;
  }

  .personalization__crm-table {
    border-bottom: $table-border-width solid v.$defaultCopy;
    color: v.$defaultCopy;
    position: relative;
    @include tm.typeData;
    font-size: 16px;

    .personalization__crm-table-labels {
      display: flex;
      font-weight: 600;
    }

    .fakeRadioSpacer {
      background: white;
      width: 50px;
      border-left: $table-border-width solid transparent;
      border-right: $table-border-width solid v.$defaultCopy;
    }

    .personalization__crm-table-rows {
      overflow: -moz-scrollbars-vertical;
      overflow-y: scroll;
      max-height: 180px;
      border-top: $table-border-width solid v.$defaultCopy;
      background-color: v.$defaultBg;
    }

    .personalization__crm-table-row {
      position: relative;
      display: flex;
      border-bottom: $table-border-width solid v.$defaultCopy;
      text-align: center;
      background-color: white;

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        background-color: v.$defaultMedium;
      }
    }

    .personalization__crm-table-label {
      border-right: $table-border-width solid v.$defaultCopy;
      border-top: $table-border-width solid v.$defaultCopy;
      background-color: v.$defaultBg;
      color: v.$defaultCopy;
      padding: 10px;
      flex: 1;
      text-align: center;

      &--long {
        @include m.smallDesktop {
          flex: 2;
        }
      }
    }

    .personalization__crm-option {
      border-right: $table-border-width solid v.$defaultCopy;
      padding: 10px;
      overflow: auto;
      flex: 1;

      &--long {
        @include m.smallDesktop {
          flex: 2;
        }
      }

      &--wrap {
        word-break: break-all;
      }
    }

    .radioBox {
      width: 50px;
      background-color: v.$defaultBg;
      border-left: $table-border-width solid v.$defaultCopy;
      border-right: $table-border-width solid v.$defaultCopy;
      position: relative;
    }

    .fakeRadio {
      position: absolute;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      left: calc(50% - (25px/2));
      bottom: calc(50% - (25px/2));
      border: $table-border-width solid v.$defaultCopy;
      background-color: white;
    }

    .selected .fakeRadio:after {
      background-color: v.$defaultCopy;
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      border-radius: 50%;
      left: calc(50% - (15px/2));
      bottom: calc(50% - (15px/2));
    }
  }

  .personalization__crm-summary {
    margin-top: 15px;
    text-align: center;
    color: v.$defaultCopy;
    @include tm.typeData;
    font-size: 18px;

    span {
      font-weight: 600;
    }
  }

  .personalization__cta {
    margin-right: 30px;
  }

  .personalization__cta-reset {
    color: var(--secondary);
  }
}

/**
 * Form Step 3
 *
 * User not found
 */
.personalization__not-found-step {

  .personalization__cta {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .personalization__inline-buttons-end {

    .personalization__cta {
      margin-left: 0;
      margin-right: 18px;
    }
  }
}

/**
 * Form Step 4.
 *
 * Form step where view CRM data and can make changes to CMR data
 * prior to beginning a session.
 */
.personalization__form-crm-step {
  .form__item {
    width: calc((100%/2) - (40px/2));

    @include m.smallDesktop {
      width: calc((100%/4) - (40px/2));
    }

    &:nth-child(2),
    &:nth-child(5) {
      margin-right: 0;

      @include m.smallDesktop {
        margin-right: 40px;
      }
    }

    &:nth-child(3),
    &:nth-child(6) {
      width: 100%;
      margin-right: 0;

      @include m.smallDesktop {
        width: calc((100%/2) - 40px);
      }
    }
  }

  .personalization__cta {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

/**
 * Optional step to customize welcome message.
 */
.personalize-welcome-message {

  .personalization__cta {
    margin-right: 30px;
  }
}

// end personalization

#personalization__myFavoritesPage-input {
  display: none;
}

.personalization__myFavoritesPage-link {
  @include tm.typeLabel;
  word-break: break-all;
  text-decoration: underline;
  color: v.$defaultCopy;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  display: block;
}

.personalization__welcome-message-textarea {
  margin-top: 30px;
  width: 100%;
  @include tm.typeData;
  color: v.$defaultCopy;
  font-size: 18px;
  border: 3px solid v.$defaultCopy;
  border-radius: 15px;
  padding: 20px 25px;
  resize: vertical;

  &:focus,
  &:focus-visible {
    background: #FFFBE7;
    border-color: v.$defaultCopy;
    outline: none;
  }
}

/// sidebar

.personalization__toggle {
  cursor: pointer;
  @include tm.typeCta;
  @include bm.btn;
  font-weight: 600;
  font-size: 17px;
  background-color: var(--primary);
  color: v.$defaultWhite;
  padding: 10px 15px;

  &:hover {
    background-color: var(--secondary);
  }

  &--active {
    border: 3px solid var(--primary);
    background-color: transparent;
    color: var(--primary);

    &:hover {
      background: none;
      border-color: var(--secondary);
      color: var(--secondary);
    }
  }
}

.personalization__status {
  @include tm.typeData;
  font-size: 16px;
  color: v.$defaultCopy;
  border-radius: 8px;
  background-color: v.$defaultBg;
  padding: 20px;
  margin-bottom: 14px;

  span {
    @include tm.typeLabel;
  }
}
