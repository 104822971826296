@use '../../../GlobalScss/mixins/type-mixins' as tm;

.unitLabel {
  pointer-events: none;
  // transition: all 0.25s;
  @include tm.typeCta;
  letter-spacing: 0.01rem;

  &__text {
    text-anchor: middle;
  }
}
