@use 'mixins/mixins.scss' as m;
@use 'mixins/btn-mixins.scss' as bm;
@use 'variables.scss' as v;
@use 'type.scss';

*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --primary: $defaultWhite;
  --secondary: $defaultWhite;
}

body.noScroll::-webkit-scrollbar {
  display: none;
}

body.noScroll ::-webkit-scrollbar {
  display: none;
}

// See: https://stackoverflow.com/questions/28988445/css-62-5-why-do-developers-use-it#answer-43554897
html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

button {
  @include bm.btnReset;
}

a {
  text-decoration: none;
}

%filterBtnItem {
  @include bm.btn;
  display: inline-block;
  background-color: v.$defaultWhite;
  border: 2px solid var(--secondary);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  margin: 0 10px;
  color: var(--secondary);

  @include m.breakpoint(smallDesktop) {
    margin: 0 5px;
  }
}

.content {
  position:relative;
}

// Filter button
.filterBtn,
.filterBtns {
  display: block;
  margin-bottom: 40px;
  text-align: center;

  @include m.breakpoint(largeDesktop) {
    margin-bottom: 30px;
  }

  a,
  button {
    @extend %filterBtnItem;
    min-width: 120px;

    @include m.breakpoint(smallMediumDesktop) {
      min-width: 110px;
    }

    &.filterBtn-active,
    &.active {
      background: var(--secondary);
      color: v.$defaultWhite;
      box-shadow: none;
    }
  }
}

// Filter button
.filterBtnsItem {
  @extend %filterBtnItem;

  &--active,
  &.active {
    background: var(--secondary);
    color: v.$defaultWhite;
    box-shadow: none;
  }

  &--inline + &--inline {
    margin-right: 20px;
    min-width: 120px;

    @include m.breakpoint(smallMediumDesktop) {
      min-width: 110px;
      margin-right: 10px;
    }
  }

  &--stacked + &--stacked {
    margin-top: 20px;
  }
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}