@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../../GlobalScss/variables.scss' as v;

.detailNav {
  flex-direction: row;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  display: none;

  @include m.tablet {
    display: flex;
  }

  &--centered {
    justify-content: center;
  }
  // .modal-scroll-active & {
  //   margin-top: 0;
  // }
}

// .detailHeader + & {
//   margin-top: 0;
// }

  .detailNavItem {
    display: inline-block;
    color: var(--secondary);
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    @include tm.typeCta--md;
    cursor: pointer;

    & + & {
      margin-left: 40px;

      &:before {
        content: '';
        position: absolute;
        display: block;
        left: -20px;
        height: 100%;
        width: 1px;
        background: lighten(v.$defaultCopy, 50%);
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }