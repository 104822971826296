@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/variables.scss' as v;

.slideshow {
  display: flex;
  width: 100%;
  margin-top: 40px;
  position: relative;
  border-radius: 5px;

  .slideshowControls {
    position: absolute;
    z-index: 999;
    display: block;
    position: absolute;
    background-color: v.$defaultWhite;
    width: 46px;
    height: 90px;
    top: 50%;
    margin-top: -40px;
    overflow: visible;

    &.slideshowControls-left {
      left: 0;
      border-top-right-radius: 46px;
      border-bottom-right-radius: 46px;

      &:hover .arrow {
        left: -20px;
      }

      .arrow {
        transform: rotate(90deg);
        left: -10px;
      }
    }

    &.slideshowControls-right {
      right: 0;
      border-top-left-radius: 46px;
      border-bottom-left-radius: 46px;

      &:hover .arrow {
        right: -20px;
      }

      .arrow {
        transform: rotate(-90deg);
        right: -10px;
      }
    }

    .arrow {
      width: 48px;
      display: block;
      position: absolute;
      top: 50%;
      transition: all 0.3s ease;

      span {
        position: absolute;
        width: 29px;
        height: 3px;
        top: 50%;
        background-color: v.$defaultCopy; // Default theme
        display: inline-block;
        border-radius: 3px;
        transform-origin: center center;

        &:first-of-type {
          left: 0;
          transform: rotate(45deg);
        }

        &:last-of-type {
          right: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .slideshowContainer {
    position: relative;
    padding-bottom: 57%;
    flex: 1;
    display: flex;
    overflow: hidden;

    .slideshowBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      filter: blur(5px) opacity(50%);
    }

    .slideshowPhoto {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
