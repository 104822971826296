@use '../../../GlobalScss/variables.scss' as v;
@use '../../../GlobalScss/mixins/mixins.scss' as m;

.mapCompassRose {
  position: absolute;
  inset: 20px 30px auto auto;
  width: 85px;
  height: 109px;

  @include m.smallDesktop {
    inset: 30px 40px auto auto;
  }

  svg {
    fill: v.$defaultCopy;
    width: 85px;
    height: 109px;
  }
}