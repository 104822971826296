@use '../../../GlobalScss/mixins/mixins.scss' as m;

.mapFloor__wrapper {
  bottom: 30px;
  left: 30px;
  position: absolute;
  z-index: 7000;

  @include m.smallDesktop {
    bottom: 40px;
    left: 40px;
  }
}
.mapFloor {
  
  .onImageDropdown__options {
    max-height: 500px;
    overflow: scroll
  }
}