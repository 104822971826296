@use '../../../../GlobalScss/mixins/type-mixins.scss' as tm;

.main-nav__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary);
  fill: var(--primary);
  text-decoration: none;
  padding: 10px;
  margin-bottom: 10px;
  transition: all 0.4s ease;
  border-radius: 8px;

  &:hover {
    color: var(--secondary);
    background-color: var(--secondary--light--80);

    svg {
      fill: var(--secondary);
    }
  }

  &.main-nav__item.active {
    cursor: default;
    background: var(--primary--light--10);

    .main-nav__label {
      color: var(--primary);
    }

    svg {
      fill: var(--primary);
    }
  }

  .main-nav__icon {
    width: 40px;
    display: flex;
    justify-content: center;

    svg {
      transition: all 0.4s ease;
    }

  }

  .main-nav__label {
    @include tm.typeCta--mdFixed;
  }
}
