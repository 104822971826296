@use 'mixins/mixins' as m;
@use 'mixins/type-mixins' as tm;
@use 'variables' as v;

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 40px 40px;
  grid-gap: 40px 40px; // for old iOS

  @include m.breakpoint(largeDesktop) {
    gap: 30px 30px;
    grid-gap: 30px 30px; // for old iOS
  }

  @include m.breakpoint(mediumDesktop) {
    grid-template-columns: 1fr 1fr;
  }

  @include m.breakpoint(smallDesktop) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.gridItem {
  position: relative;
}

.gridItemInfo {
  padding: 30px 30px 0;

  @include m.breakpoint(largeDesktop) {
    padding: 20px 30px 0;
  }
}

.gridItemTrigger {
  display: block;
  width: 100%;
  border-radius: 5px;
  position: relative;
  transition: all 0.5s ease;


  &:hover {
    box-shadow: 0 0 0 4px var(--secondary);
    cursor: pointer;

    .imgHolder {
      transform: scale(1.2);
    }
  }

  .imgContainer {
    //background: var(--secondary);

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      display: block;
      height: 150px;
      width: 100%;
      bottom: 0;
      left: 0;
      opacity: 0.6;
      background-image: linear-gradient(
        180deg,
        rgba(8, 18, 26, 0) 6%,
        #08121a 100%
      );
    }
  }

  .gridItemTitle {
    width: 70%;
  }

  .gridItemBanner {
    position: absolute;
    z-index: 1;
    background: v.$defaultAlert;
    box-shadow: 0 0 0 999px v.$defaultAlert;
    inset: auto 0 0 auto;
    transform-origin: 0 100%;
    transform: translate(29.3%) rotate(-45deg);
    clip-path: inset(0 -100%);
    padding: 5px;

    &__text {
      color: white;
      text-transform: uppercase;
      @include tm.typeDisplay--md;
    }
  }

  // .cameraIcon {
  //   width: 34px;
  //   height: 28px;
  //   position: absolute;
  //   z-index: 3;
  //   bottom: 32px;
  //   right: 35px;
  //   padding: 0;
  //   margin: 0;

  //   svg {
  //     fill: v.$defaultWhite;
  //     filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.5));
  //   }
  // }
}

.imgContainer {
  height: 360px;
  border: 4px solid transparent;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  z-index: 1;

  .gridItemTitle {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    padding: 30px;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.75);
    color: v.$defaultWhite;
  }
}

.imgHolder {
  width: 100%;
  height: 100%;
  background-color: v.$defaultWhite;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: all 0.5s ease;

  &--noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    @include tm.typeBody--md;
    color: v.$defaultCopy;
  }
}

// Specific to media and floor plans page
.media,
.floorplans {
  .gridItemTitle {
    width: 100% !important;
  }
}

// Specific to floor Plans page
.floorplans {
  .gridItem {
    button {
      .imgContainer {
        &:before {
          display: none;
        }

        .imgHolder {
          background-size: contain;
          background-origin: content-box;
          padding: 30px;
        }
      }
    }
  }
}
