@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/variables.scss' as v;

.map {
  flex-grow: 1;
  overflow: auto;
  position: relative;
  background-color: v.$defaultBg; // Default theme
}

.mapShell {
  height: 100%;
}

// Floor selection and unit filters
.mapSelector {
  color: v.$defaultCopy; // Default theme

  button {
    text-decoration: none;
    color: v.$defaultCopy; // Default theme
  }
}

.mapSelector-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
 // position: absolute;
  z-index: 7000;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 20px 50px 0 v.$defaultShadow; // Default theme
  border-radius: 45px;

  .mapSelector-selection {
    display: flex;
    padding: 12px 22px;
    border-radius: 45px;
  }

  .mapSelector-btn {
    background-color: white;
    position: relative;
    padding: 12px 8px;
    border-radius: 45px;
    transition: all 0.3s ease;

    @include m.breakpoint(mediumDesktop) {
      padding: 8px 4px;
    }

    &.mapSelector-btn--active {
      background-color: v.$defaultCopy; // Default theme
    }
  }

  .mapSelector-options {

    .mapSelector-option {
      display: block;
      text-align: left;
      width: 100%;
      padding: 12px 24px 10px;
      border-bottom: 1px solid lighten(v.$defaultCopy, 50%); // Default theme
      transition: all 0.3s ease;
      color: v.$defaultCopy; // Default theme
      margin: 0; // override safari defaults

      @include m.breakpoint(mediumDesktop) {
        padding: 10px 22px 8px;
      }

      @include m.breakpoint(smallDesktop) {
      }

      &:hover {
        background-color: v.$defaultBg; // Default theme
      }

      &.mapSelector-option--active {
        background-color: v.$defaultCopy; // Default theme
        color: v.$defaultBg;
      }

      &:last-child {
        border: none;
      }
    }
  }
}