@mixin bouncy {
  -webkit-animation: bouncy 0.6s;
  animation: bouncy 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bouncy {
  from, to {
    -webkit-transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
  } 
}

@keyframes bouncy {
  from, to {
    transform: scale(1, 1); 
  }
  25% {
    transform: scale(0.9, 1.1); 
  }
  50% {
    transform: scale(1.1, 0.9); 
  }
  75% {
    transform: scale(0.95, 1.05); 
  } 
}