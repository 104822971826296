@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/variables.scss' as v;

.mapFilter__wrapper {
  position: absolute;
  top: 30px;
  left: 30px;

  @include m.smallDesktop {
    top: 40px;
    left: 40px;
  }
}

.mapFilter-container {
  .mapFilter-options {
    position: absolute;
    top: 80px;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.5s ease;
    pointer-events: none;

    @include m.breakpoint(mediumDesktop) {
      top: 70px;
    }

    .mapFilter-flex {
      display: flex;
      align-items: flex-start;
    }

    .mapFilter-panel {
      position: relative;
      transition: all 0.3s ease;

      &.mapFilter-panel--disabled {
        opacity: 0.7;
        filter: blur(2px);
      }
    }

    .mapFilter-backBtn {
      display: none;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    .mapFilter-panel-options {
      background-color: v.$defaultWhite;
      border-radius: 10px;
      box-shadow: 0 0 30px 0 v.$defaultShadow; // Default theme
      overflow: scroll;
      width: 320px;
      margin: 0 10px;
      max-height: 60vh;
      -ms-overflow-style: none;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .mapFilter-panel-options--show {
      opacity: 1;
    }

    .mapFilter-panel-options--category {
      &:last-child {
        .mapFilter-accordion-trigger {
          border-bottom: none;
        }
      }

      .mapFilter-accordion-trigger {
        background-color: v.$defaultCopy;
        color: v.$defaultLight;
        padding: 18px 12px 18px 65px;
        display: block;
        border: none;
        margin:none;
        outline: none;
        appearance: none;
        border-bottom: 1px solid v.$defaultShadow;
        position: relative;
        width: 100%;
        text-align: left;
        margin: 0;

        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 24px;
          height: 24px;
          background: url('../../../SVGAssets/arrow-left-white.svg') no-repeat;

          top: 50%;
          left: 24px;
          transform: translateY(-50%);
          transition: all 0.3s ease;
        }
      }


      .mapFilter-accordion {
        overflow: hidden;
        transition: max-height 1s ease;
      }
    }

    .mapFilter-panel:first-child {
      .mapFilter-panel-options {
        display: block;
      }
    }

    .mapFilter-optionName {
      display: block;
      max-width: 80%;
    }

    .mapFilter-option-tag {
      display: inline-block;
      background: v.$defaultCopy;
      color: #ffffff; // Use variable here too.

      font-size: 16px;
      padding: 4px 5px 3px 7px;
      margin-right: 10px;
      border-radius: 3px;
      position: relative;

      &:hover {
        cursor: default;
      }

      .mapFilter-option-tag--remove {
        text-indent: -100vw;
        width: 15px;
        height: 15px;
        display: inline-block;
        background: url('../../../SVGAssets/close-icon-white.svg') no-repeat;
        background-position: center;
        transform: translateY(3px);

        &:hover {
          cursor: pointer;
        }
      }
    }

    .mapFilter-option--more {
      position: relative;
      padding-right: 70px;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        background: url('../../../SVGAssets/arrow-right.svg') no-repeat;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        transition: all 0.3s ease;
      }

      &:hover {
        &:after {
          right: 20px;
        }
      }
    }

    .mapFilter-option.mapFilter-option--floorPlan {
      display: flex;
      align-items: center;
      color: v.$defaultCopy;
      text-align: left;
    }

    .mapFilter-option.mapFilter-option--floorPlanDisabled {
      color: lighten(v.$defaultCopy, 40%); // Default theme
      font-weight: 300;
    }

    .mapFilter-option--floorPlanColor {
      flex-shrink: 0;
      display: block;
      margin-right: 20px;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      border: 3px solid v.$defaultCopy; // Default theme
      background-color: transparent;
      position: relative;

      transition: all 0.3s ease;
    }

    .mapFilter-option--active {
      background-color: v.$defaultCopy;

      svg {
        position: absolute;
        top: 0;
        width: 18px;
      }
    }
  }

  .mapFilter-options.mapFilter-options--open  {
    opacity: 1;

    .mapFilter-panel--active,
    .mapFilter-panel--disabled .mapFilter-backBtn {
      pointer-events: all;
    }
  }

  .mapFilter--clear-filters {
    background-color: #DBDECF;
    text-transform: uppercase;
    display: block;
    text-align: center;
    width: 100%;
    padding: 12px 33px;
    margin: 0;
  }

  .mapFilter--no-results-text {
    text-align: center;
    text-transform: none;
    padding: 15px 25px;
  }
}
