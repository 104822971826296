@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/variables.scss' as v;

.pageFilters {
  display: flex;
  justify-content: space-between;
}

.pageFilters__pageFilter {
  position: relative;
  height: 100%;
  margin: 0 5px 40px;

  @include m.breakpoint(largeDesktop) {
    margin: 0 5px 30px;;
  }
}

.pageFilters__filterBtn {
  svg {
    background-color: var(--secondary); // Secondary color
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 44px;
    border-radius: 5px;
    fill: v.$defaultWhite;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;

    &.open {
      top: 14px;

      & path {
        transform: translateY(10%) rotate(180deg);
      }
    }

    @include m.breakpoint(smallMediumDesktop) {
      width: 36px;
    }

    polygon {
      transform: translateY(10%) scale(0.4);
      transform-origin: 50% 50%;
      transition: all 0.3s ease;
    }

    &.open polygon {
      transform: translateY(10%) scale(0.4) rotate(180deg);
    }
  }

  &.open, &:hover {
    .pageFilters__filterBtn-label {
      background-color:var(--secondary--lightened);
    }
  }
}

.pageFilters__filterBtn-label {
  display: inline-block;
  border: 2px solid var(--secondary); // Secondary color
  border-radius: 5px;
  padding: 10px 20px 8px;
  margin: 0 48px 0 0;
  min-width: 120px;
  font-family: v.$condensed;
  font-size: 20px;
  letter-spacing: 1px;
  color: var(--secondary); // Secondary color
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s ease;
  background-color: v.$defaultWhite;

  @include m.breakpoint(smallMediumDesktop) {
    font-size: 18px;
    padding: 8px 16px 6px;
    margin: 0 40px 0 0;
  }

  &.filterBtn-active {
    background: var(--secondary); // Secondary color
    color: v.$defaultWhite;
    box-shadow: none;
  }
}

.pageFilters__filterBtn-options {
  position: absolute;
  z-index: 10;
  background-color:var(--secondary); // Secondary color
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.20);
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 12px;
  right: 0;
  max-width: 100%;
  // Visibility of the dropdown is managed through gsap.
  opacity: 0;
  display: none;

  .pageFilters__filterBtn-option {
    display: block;
    width: 100%;
    font-family: v.$condensed;
    font-size: 20px;
    letter-spacing: 1px;
    color: v.$defaultWhite;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px 0;
    transition: all 0.3s ease;

    &:first-child {
      border-top: none;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.filterBtn-option--disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }

    @include m.breakpoint(smallMediumDesktop) {
      font-size: 18px;
    }
  }
}