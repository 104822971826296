@use 'mixins/mixins' as m;

// @TODO Everything using these styles needs to be switched to using src/GlobalScss/mixins/_type-mixins.scss

$sans-serif: 'Roboto', sans-serif;
$condensed: 'Roboto Condensed', sans-serif;

%type-cta {
  font-family: $condensed;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  line-height: 1.3;
}

.type-cta {
  &-md-fixed {
    @extend %type-cta;
    font-size: 20px;
  }

  &-sm {
    @extend %type-cta;
    font-size: 18px;
  }

  &-md {
    @extend %type-cta;
    font-size: 20px;

    @include m.breakpoint(smallMediumDesktop) {
      font-size: 18px;
    }
  }

  &-lg {
    @extend %type-cta;
    font-size: 22px;
  }
}

%type-cta-alt {
  font-family: $condensed;
  letter-spacing: 0.08rem;
  line-height: 1.3;
}

.type-cta-alt {
  &-md {
    @extend %type-cta-alt;
    font-size: 22px;

    @include m.breakpoint(smallDesktop) {
      font-size: 20px;
    }
  }

  &-md-fixed {
    @extend %type-cta-alt;
    font-size: 20px;
  }

  &-lg-fixed {
    @extend %type-cta-alt;
    font-size: 24px;
  }
}

%type-display {
  font-family: $sans-serif;
  letter-spacing: 0.1rem;
  line-height: 1.3;
}

.type-display {

  &-m {
    @extend %type-display;
    font-size: 21px;
  }

  &-lg {
    @extend %type-display;
    font-size: 24px;
  }

  &-xl {
    @extend %type-display;
    font-weight: 500;
    font-size: 36px;

    @include m.breakpoint(largeDesktop) {
      font-size: 32px;
    }
  }

  &-xxl {
    @extend %type-display;
    font-size: 42px;
    font-weight: 300;

    @include m.breakpoint(smallMediumDesktop) {
      font-size: 38px;
    }

    @include m.breakpoint(smallDesktop) {
      font-size: 34px;
    }

    &-bd {
      @extend .type-display-xxl;
      font-weight: 500;
    }
  }
}

%type-body {
  font-family: $sans-serif;
  letter-spacing: 0.06rem;
  line-height: 1.7;
  font-weight: 400;
}

.type-body {
  &-md {
    @extend %type-body;
    font-size: 20px;

    @include m.breakpoint(largeDesktop) {
      font-size: 18px;
    }

    @include m.breakpoint(smallDesktop) {
      font-size: 15px;
    }
  }

  &-bd {
    font-weight: 600;
  }
}
