.furnitureConfigurator {
  position: relative;

  &__iframe {
    position:absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border: none;
  }
}