@use '../../../GlobalScss/mixins/_type-mixins' as t;
@use '../../../GlobalScss/variables' as v;

.slideshowPhoto {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &__captionWrapper {
    position: absolute;
    inset: 40px;
  }

  &__caption {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 100%;
    color: v.$defaultCopy;
    background-color: rgba(v.$defaultWhite, 0.8);
    padding: 15px 30px;
    border-radius: 10px;
    @include t.typeBody--md;
    line-height: 1.4;
  }
}
