@use '../../GlobalScss/mixins/mixins.scss' as m;

.virtualTour {
  position: relative;
  margin-top: 40px;
  // 16:9 ratio
  padding-bottom: 52.65%;

  &--TruPlace {
    // truplace requires 4:3 ratio
    padding-bottom: 75%;
    margin-bottom: -35px;
  }

  &__iframe {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;

    // hide truplace margins that come within their iframe
    &--TruPlace {
      top: -35px;
      bottom: -35px;
      left: -15px;
      right: -15px;
      width: calc(100% + 30px);
      margin-bottom: 0;
    }
  }

  &__dropdown {
    position: absolute;
    bottom: 20px;
    left: 20px;
    // safari doesn't honor z-index for iframe
    // so we have to use this hack
    -webkit-transform: translate3d(0,0,1px);

    @include m.smallDesktop {
      bottom: 30px;
      left: 30px;
    }

    // compensate for things we have to do to hide truplace iframe internal margins
    // and offset for truplace copyright(24px)
    &--TruPlace {
      bottom: auto;
      top: 75%;

      @include m.smallDesktop {
        bottom: auto;
      }
    }
  }
}