.floorplans {

  &.content {
    padding: 40px;
  }

  .mediaTours,
  .mediaVideos,
  .floorplanCat-2 {
    display: none;
  }

  .mapFilters {
    position: relative;
    height: 65px;
    margin-bottom: 40px;
  }

  .mapFilter-container {
    top: 0px;
    left: 0px;
  }

  .mapFilter__wrapper {
    position: relative;
    top:0;
    left:0;
  }

  .pageFilters {
    position: relative;
    z-index:1;
  }

  .gridContainer {
    position: relative;
    z-index:0;
  }
}
