@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/variables.scss' as v;

.resourcesDetail {

  &-row {
    @include tm.typeBody--md;
    width: 100%;

    @include m.phone {
      padding: 20px 0;
    }

    @include m.tablet {
      display: flex;
      padding: 30px;
    }

    h2 {
      line-height: 1;
      color: v.$defaultCopy;
      font-weight: 400;
      font-size: 25px;
      @include m.phone {
        text-align: center;
        padding-bottom: 15px;
      }
      @include m.tablet {
        flex: 25%;
        position: relative;
      }
      @include m.smallDesktop {
        flex: 20%;
      }
      @include m.smallMediumDesktop {
        flex: 15%;
      }
      &:after {
        @include m.tablet {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 2px;
          height: 100%;
          background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, v.$defaultSeparatorColor 50%, v.$defaultSeparatorColor 100%);
          background-size: 100% 11px;
        }
      }
    }

    ul {
      width: 100%;
      @include m.tablet {
        flex: 75%;
        margin: 0;
        padding-left: 50px;
      }
      @include m.smallDesktop {
        flex: 80%;
        column-count: 2;
      }
      @include m.smallMediumDesktop {
        flex: 85%;
      }
    }
    li {
      color: v.$defaultCopy;
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
    &:nth-child(2) {
      background-color: v.$defaultBg;
    }
  }

}