@use '../../../GlobalScss/variables' as v;
@use '../../../GlobalScss/mixins/animation-mixins' as am;

.favoriteBtn-positioner {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
}

.favoriteBtn {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: v.$defaultBg;
  display: flex;
  justify-content: center;
  align-items:center;
  margin: 0 auto;
  text-decoration: none;
  overflow: hidden;
	border: none;
	outline: none;
	transition: all .4s;
  cursor: pointer;

  &-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: #E06161;
    transform: scale(0);
    transition: all .4s;
    z-index: 0;
  }

  i {
    font-size: 28px;

    &.not-liked {
      @include am.bouncy;
      display: block;
      color: v.$defaultCopy;
      position: relative;
      z-index: 1;
    }

    &.is-liked {
      @include am.bouncy;
      display: none;
      color: v.$defaultWhite;
      position: relative;
      z-index: 1;
    }
  }

  &--shadow {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }

  &--active {
    background: #E06161;

    .favoriteBtn-overlay {
      transform: scale(1);
    }

    i {
      &.not-liked {
        display: none;
      }

      &.is-liked {
        display: block;
      }
    }
  }
}