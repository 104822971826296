@use '../../GlobalScss/mixins/mixins' as mixins;

$sans-serif: 'Roboto', sans-serif;
$condensed: 'Roboto Condensed', sans-serif;

@mixin typeCta {
  font-family: $condensed;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  line-height: 1.3;
}

@mixin typeCta--md {
  @include typeCta;
  font-size: 18px;

  @include mixins.mediumDesktop {
    font-size: 20px;
  }
}

@mixin typeCta--mdFixed {
  @include typeCta;
  font-size: 20px;
}

@mixin typeCta--lgFixed {
  @include typeCta;
  font-size: 22px;
}

@mixin typeCtaAlt {
  font-family: $condensed;
  letter-spacing: 0.08rem;
  line-height: 1.3;
}

@mixin typeCtaAlt--md {
  @include typeCtaAlt;
  font-size: 20px;

  @include mixins.mediumDesktop {
    font-size: 22px;
  }
}

@mixin typeCtaAlt--mdFixed {
  @include typeCtaAlt;
  font-size: 20px;
}

@mixin typeCtaAlt--lgFixed {
  @include typeCtaAlt;
  font-size: 24px;
}

%type-display {
  font-family: $sans-serif;
  letter-spacing: 0.1rem;
  line-height: 1.3;
}

@mixin typeDisplay {
  font-family: $sans-serif;
  letter-spacing: 0.1rem;
  line-height: 1.3;
}

@mixin typeDisplay--sm {
  @include typeDisplay;
  font-size: 15px;

  @include mixins.smallDesktop {
    font-size: 18px;
  }

  @include mixins.largeDesktop {
    font-size: 20px;
  }
}

@mixin typeDisplay--md {
  @extend %type-display;
  font-size: 18px;
}

@mixin typeDisplay--lg {
  @extend %type-display;
  font-size: 24px;
}

@mixin typeDisplay--xl{
  @extend %type-display;
  font-weight: 500;
  font-size: 32px;

  @include mixins.mediumDesktop {
    font-size: 36px;
  }
}

@mixin typeDisplay--xxl {
  @extend %type-display;
  font-weight: 300;
  font-size: 34px;

  @include mixins.mediumDesktop {
    font-size: 38px;
  }

  @include mixins.largeDesktop {
    font-size: 42px;
  }
}

@mixin typeDisplay--xxl--bd {
  @include typeDisplay--xxl;
  font-weight: 500;
}

@mixin typeWysiwyg {
  ul {
    margin-left: 20px;
    margin-bottom: 0;
    padding-inline-start: 0;
  }

  ul + ul,
  ul + p,
  p + ul,
  p + p {
    margin-top: 20px;
  }
}

@mixin typeBody {
  font-family: $sans-serif;
  letter-spacing: 0.06rem;
  line-height: 1.7;
  font-weight: 400;
}

@mixin typeBody--md {
  @include typeBody;
  font-size: 15px;

  @include mixins.smallDesktop {
    font-size: 18px;
  }

  @include mixins.largeDesktop {
    font-size: 20px;
  }
}

@mixin typeBody--md--bd {
  font-weight: 600;
}

@mixin typeBody--md--it {
  font-style: italic;
}

@mixin typeText {
  font-family: $sans-serif;
  letter-spacing: 0.06rem;
  line-height: 1.4;
}

@mixin typeLabel {
  @include typeText;
  font-weight: 500;
}

@mixin typeData {
  @include typeText;
  font-weight: 400;
}