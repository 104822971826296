@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../../GlobalScss/variables.scss' as v;

.main-nav__wrapper {
  display: flex;
  flex: 0 0 375px;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  text-align: center;
  padding: 60px;
  box-shadow: -10px 10px 25px 0 rgba(175, 176, 170, 0.3);
  box-sizing: border-box;
  transition: all 0.6s ease;
  position: relative;
  z-index: 7500;

  @include m.breakpoint(largeDesktop) {
    flex: 0 0 340px;
    padding: 50px;
  }

  @include m.breakpoint(mediumDesktop) {
    flex: 0 0 320px;
    padding: 40px;
  }

  @include m.breakpoint(smallDesktop) {
    padding: 30px;
  }

  @include m.breakpoint(tablet) {
    flex: 0 0 200px;
  }

  &.main-nav__wrapper--active {
    margin-left: -350px;

    @include m.breakpoint(largeDesktop) {
      margin-left: -315px;
    }

    @include m.breakpoint(mediumDesktop) {
      margin-left: -295px;
    }

    @include m.breakpoint(tablet) {
      margin-left: -175px;
    }
  }
}

.logo {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  background-size: contain;
  height: 150px;

  @include m.breakpoint(largeDesktop) {
    height: 120px;
  }
}

.main-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.main-nav__toggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40px;
  z-index: 8000;
  display: block;
  width: 40px;
  height: 80px;
  background-color: v.$defaultWhite;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: -10px 10px 25px 0 rgba(175, 176, 170, 0.3);

  @include m.breakpoint(mediumDesktop) {
    width: 30px;
    height: 60px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    right: -30px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: -30px;
    background-color: v.$defaultWhite;
    width: 30px;
    height: 100px;

    @include m.mediumDesktop {
      width: 40px;
      height: 120px;
      left: -40px;
    }
  }

  @media not all and (pointer: coarse) {
    // Gets rid of hover effect on touchscreens
    &:hover {
      .main-nav__toggleArrow {
        left: -20px;

        &.main-nav__toggleArrow--active {
          left: 0;
        }

        span {
          background-color: darken(v.$defaultBg, 50%); // Default theme
        }
      }
    }
  }

  &Arrow {
    width: 30px;
    display: block;
    position: absolute;
    transform: rotate(90deg);
    top: 50%;
    left: -10px;
    transition: all 0.3s ease;

    @include m.mediumDesktop {
      width: 40px;
    }

    span {
      top: 0;
      position: absolute;
      width: 1.8rem;
      height: 3px;
      background-color: darken(v.$defaultBg, 25%); // Default theme
      display: inline-block;
      border-radius: 3px;
      transition: all 0.3s ease;

      @include m.mediumDesktop {
        width: 2.4rem;
      }

      &:first-of-type {
        left: 0;
        transform: rotate(45deg);
      }

      &:last-of-type {
        right: 0;
        transform: rotate(-45deg);
      }
    }

    &--active {
      span {
        &:first-of-type {
          transform: rotate(-45deg);
        }

        &:last-of-type {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.sidebar__message {
  @include tm.typeData;
  font-style: italic;
  font-size: 14px;
  color: v.$defaultCopy;
  margin-top: 30px;

  & + & {
    margin-top: 20px;
  }

  &Cta {
    font-style: italic;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
    color: v.$defaultCopy;
    cursor: pointer;
  }
}