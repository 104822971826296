@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/mixins/btn-mixins.scss' as bm;
@use '../../GlobalScss/variables.scss' as v;

@mixin pibBand {
  padding: 30px 20px;

  @include m.tablet {
    padding: 40px;
  }

  @include m.smallMediumDesktop {
    padding: 50px 40px;
  }
}

.pibPage {
  flex: 1;

   &-branding {
    @include pibBand;
    background-color: v.$defaultWhite;
    text-align: center;

    &__logoLink {
      &__logoImg {
        width: auto;
        height: auto;
        max-height: 75px;
        max-width: 200px;
        display: inline-block;

        @include m.tablet {
          max-width: 260px;
          max-height: 100px;
        }
      }
    }
  }

  &-welcome {
    @include pibBand;
    background-color: var(--primary);
    text-align:center;
    color: v.$defaultWhite;

    &__intro {
      @include tm.typeDisplay;
      font-weight: 600;
      padding-bottom: 30px;
      font-size: 24px;

      @include m.tablet {
        font-size: 36px;
      }
    }

    &__contact {
      @include tm.typeDisplay;
      font-size: 18px;
      padding-bottom: 15px;
      font-size: 18px;

      @include m.tablet {
        font-size: 21px;
        padding-bottom: 30px;
      }

      a {
        color: v.$defaultWhite;
        text-decoration: underline;
      }
    }

    &__date {
      @include tm.typeDisplay--sm;

      span {
        font-style: italic;
      }
    }
  }

  &-message {
    @include pibBand;
    background-color: v.$defaultBg;
    @include tm.typeBody--md;
    color: v.$defaultCopy;

    &__body {
  //    padding-bottom: 30px;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      @include tm.typeWysiwyg;
    }

    &__signature {
      font-style: italic;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-favorites {
    @include pibBand;

    &__detailNav {
      justify-content: center;
      margin-bottom: 40px;
    }

    &__stickyContent {
      color: v.$defaultCopy;
      padding: 0 20px 30px;

      @include m.tablet {
        padding: 0 40px 40px;
      }

      .subpaneButtons + & {
        padding-top: 30px;

        @include m.tablet {
          padding-top: 40px
        }
      }

      &__title {
        @include tm.typeDisplay;
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;

        @include m.tablet {
          font-size: 38px;
          margin-bottom: 40px;
        }
      }

      &__wysiwyg {
        @include tm.typeBody--md;
        @include tm.typeWysiwyg;

        &--centered {
          text-align: center;
        }

        a {
          color: v.$defaultCopy;
          text-decoration: underline;
        }

        ul {
          @include m.tablet {
            column-count: 2;
          }
        }
      }
    }
  }

  &-backToTop {
    margin-bottom: 40px;
    text-align: center;
    display: none;

    @include m.tablet {
      display: block;
    }

    @include m.smallDesktop {
      margin-bottom: 50px;
    }

    &__button {
      display: inline-block;
      text-align: center;
      transition: v.$standardTrans;

      &:hover {
        transform: translateY(-10px);
      }

      &__arrow {
        @include bm.upArrowGraphic(40px, v.$defaultCopy);
      }

      &__text {
        margin-top: 20px;
        @include tm.typeCta--md;
        color: v.$defaultCopy;
        font-weight: 600;
        display: block;
      }
    }
  }

  &-vectre {
    @include pibBand;
    background-color: v.$defaultBg;
    @include tm.typeBody--md;
    color: v.$defaultCopy;
    text-align: center;

    &__message {
      padding-bottom: 30px;

      span {
        font-weight: 600;
      }
    }

    &__colophon {

    }
  }

}