@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/variables.scss' as v;

.mapControls__wrapper {
  position: absolute;
  z-index: 8000;
  right: 30px;
  bottom: 30px;

  @include m.smallDesktop {
    right: 40px;
    bottom: 40px;
  }
}

.mapControls {
  display: flex;
  align-items: center;
  background: rgba(v.$defaultWhite, 0.6);
  box-shadow: 0 20px 50px 0 v.$defaultShadow; // Default theme
  border-radius: 45px;
  overflow: hidden;
  padding: 12px 10px;
  border-radius: 45px;
}

  .mapControl,
  .mapControlToggle,
  .mapControlToggle-toggleOption {
    display: inline-block;
    width: 30px;
    height: 30px;
    transition: v.$standardTrans;

    @include m.mediumDesktop {
      height: 38px;
      width: 38px;
    }

    svg {
      fill: v.$defaultCopy; // Default theme
      overflow: visible;
    }

    &--disabled {
      opacity: 0.4;
      cursor: default;
      pointer-events: none;

      &:hover {
        transform: none;
      }

      .mapControlToggle-toggleOption {
        pointer-events:none;

        &:hover {
          transform: none;
        }
      }
    }
  }

  .mapControl,
  .mapControlToggle {
    margin: 0 5px;
    position: relative;

    &:hover {
      transform: scale(1.2);
    }

    // &--disabled {
    //   opacity: 0.4;
    //   cursor: default;
    //   pointer-events:none;

    //   &:hover {
    //     transform: none;
    //   }
    // }
  }

  .mapControlToggle-toggleOption {
    position: absolute;
    width: 30px;
    top:0;
    left: 0;
    opacity:0;
    pointer-events:none;

    @include m.mediumDesktop {
      width: 38px;
    }

    &--show {
      opacity:1;
      pointer-events:all;
    }
  }

.mapToggle {
  margin: 5px 20px 0;

  @include m.breakpoint(mediumDesktop) {
    margin: 3px 12px 0;
  }

  button {
    color: v.$defaultCopy; // Default theme
    padding: 0 5px;
    opacity: 0.8;
    font-weight: 400;

    @include m.breakpoint(mediumDesktop) {
      padding: 0 3px;
    }

    &.mapToggle-map--active {
      font-weight: 500;
      opacity: 1;
    }
  }
}
