@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/btn-mixins.scss' as bm;
@use '../../GlobalScss/mixins/type-mixins' as t;
@use '../../GlobalScss/variables.scss' as v;

.floorPlanImagesWidget {

  @include m.tablet {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-images {
    position: relative;

    @include m.tablet {
      width: 85%;
    }

    &__image {
      width: 100%;

      @include m.tablet {
        position: relative;
        opacity: 1;
        transition: all 0.6s ease;
        max-height: 600px;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
      }

      &--inactive {

        @include m.tablet {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      & + & {
        margin-top: 20px;

        @include m.tablet {
          margin-top: 0;
        }
      }
    }
  }

  &-buttons {

    @include m.tablet {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 30px;
      width: 15%;
    }

    @include m.smallDesktop {
      padding: 0 0 0 40px;
    }

    @include m.largeDesktop {
      padding: 0 0 0 60px;
    }

    &__button {
      @include bm.secondaryBtn;

      &:not(.downloadPdf) {
        @include m.phone {
          display: none;
        }
      }

      &.downloadPdf {
        @include m.phone {
          width: 100%;
          transition: none;
          @include bm.secondaryBtn--active;
        }
        @include m.tablet {
          line-height: 0;
        }

        svg {
          @include m.phone {
            display: none;
          }
          g {
            @include bm.svgDynamicStroke;
          }
        }

        .phoneText {
          @include m.tablet {
            display: none;
          }
        }
      }

      & + & {
        margin-top: 20px;
      }

      &--active {
        @include bm.secondaryBtn--active;
      }
    }
  }

  &-disclaimer {
    @include t.typeText;
    font-size: 16px;
    font-style: italic;
    color: v.$defaultCopy;
    padding-top: 40px;
    text-align: center;
  }
}