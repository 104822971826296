@use '../../../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../../../GlobalScss/variables.scss' as v;

.pMarker {
  &__trigger {
    font-family: v.$sans-serif;

    &__place {
      font-size: 1.8rem;
      fill: v.$defaultCopy;
      cursor: pointer;

      &.show__nonRichLabel {
        display: none;
      }
    }
  }

  &__isLink {
    fill: v.$defaultWhite;
  }

  &__text {
    @include tm.typeCtaAlt--mdFixed;
    pointer-events: none;
    fill: v.$defaultCopy;
    line-height: 1;

    .pMarker--link & {
      // We specify fill because default x/y is 0,0 and it makes a weird transition.
      transition: fill 0.3s ease;
    }

    .pMarker--link:hover & {
      fill: v.$defaultWhite;
    }

    &--right {
      text-anchor: start;
    }

    &--left {
      text-anchor: end;
    }

    &--top,
    &--bottom {
      text-anchor: middle;
    }

    &__divider {
      fill: lighten(v.$defaultCopy, 50%);
    }
  }

  &__bg {
    fill: v.$defaultWhite;
    // This has to be done with a filter because the marker is resized on zoom
    // and distorts the shadow.
    filter: url(#markerShadow);

    .pMarker--link & {
      // We specify fill because default x/y is 0,0 and it makes a weird transition.
      transition: fill 0.3s ease;
      cursor: pointer;
    }

    .pMarker--link:hover & {
      fill: v.$defaultCopy;
    }
  }

  &__dot {
    fill: v.$defaultWhite;
    // This has to be done with a filter because the marker is resized on zoom
    // and distorts the shadow.
    filter: url(#markerShadow);

    .pMarker--link & {
      // We specify fill because default x/y is 0,0 and it makes a weird transition.
      transition: fill 0.3s ease;
    }

    .pMarker--link:hover & {
      fill: v.$defaultCopy;
    }
  }
}

// This is a workaround for Safari 16 which doesn't support the
// hanging-punctuation property. It's used to remove the shadow filter since
// Safari has major performance issues with it.
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .pMarker__bg,
  .pMarker__dot {
    filter: none;
  }
}