@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/variables.scss' as v;

.detailHeader {
  color: v.$defaultCopy;
  text-align: center;
  @include tm.typeDisplay;

  &--hasSubtitle {
    margin-bottom: 30px;

    @include m.tablet {
      margin-bottom: 40px;
    }
  }

  &__title {
    display: none;
    font-size: 38px;

    @include m.tablet {
      display: block;
      padding-bottom: 10px;
    }
  }

  &__subtitle {
    font-size: 21px;
  }
}