@use '../../../GlobalScss/mixins/type-mixins.scss' as tm;

.svgMapWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.svgMap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

svg text[class^='st'],
svg tspan[class^='st'] {
  @include tm.typeCtaAlt;
  letter-spacing: 0.05rem;
}

.mapBase {
  fill:url(#mapBasePattern);
  stroke:#bdbcb4;
  stroke-width:0.5;
  stroke-miterlimit:10;
}

.mapTrees {fill:url(#mapTreesPattern)}
.mapGrass {fill:url(#mapGrassPattern)}
.mapAmenityGrass {fill:url(#mapAmenityGrassPattern)}
.mapAmenityGrass2 {fill:url(#mapAmenityGrass2Pattern)}
.mapDirt {fill:url(#mapDirtPattern)}
.mapWater {fill:url(#mapWaterPattern)}
.mapShadow {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.8));
}

// This is a workaround for Safari 15.0.1, which doesn't support the
// hanging-punctuation property. It's used to remove the shadow filter since
// Safari has major performance issues with it.
@supports (hanging-punctuation: first) and (-webkit-appearance: none) {
  .mapShadow {
    filter: none;
  }
}

